
import { defineComponent } from "vue";
import { mapActions } from "vuex";

export default defineComponent({
  name: "LoginView",
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
        errorMessage: "",
      },
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    loginSubmit() {
      this.login(this.form)
        .then(() => {
          this.$router.push("dashboard");
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.form.errorMessage = error.response.data.message;
          } else {
            this.form.errorMessage =
              "Something went wrong, please try again later";
          }
        });
    },
  },
});
