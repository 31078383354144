/* eslint-disable @typescript-eslint/no-explicit-any */

export default {
  namespaced: true,

  state: {
    whichExercises: null,
    exercisesPage: null,
  },

  getters: {
    getWhichExercises(state: any) {
      return state.whichExercises;
    },
    getExercisesPage(state: any) {
      return state.exercisesPage;
    },
  },

  mutations: {
    setWhichExercises(state: any, value: object) {
      state.whichExercises = value;
    },
    setExercisesPage(state: any, value: object) {
      state.exercisesPage = value;
    },
  },
};
