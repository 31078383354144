
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import ExerciseForm from "../components/ExerciseForm.vue";

export default defineComponent({
  name: "ExerciseEditView",
  components: {
    ExerciseForm,
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      authenticated: "auth/isAuthenticated",
      adminOrEmp: "auth/isAdminOrEmp",
    }),
  },
});
