import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container pt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_exercise_form = _resolveComponent("exercise-form")!

  return (_ctx.authenticated)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.adminOrEmp)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_exercise_form)
            ]))
          : _createCommentVNode("", true)
      ], 64))
    : _createCommentVNode("", true)
}