
import { mapGetters, mapActions } from "vuex";
import { defineComponent } from "vue";
import UserSettingsModal from "../components/UserSettingsModal.vue";

export default defineComponent({
  name: "MainNavigation",
  components: { UserSettingsModal },
  computed: {
    ...mapGetters({
      authenticated: "auth/isAuthenticated",
      admin: "auth/isAdmin",
      adminOrEmp: "auth/isAdminOrEmp",
      user: "auth/getUser",
    }),
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    logoutButton() {
      this.logout().then(() => {
        this.$router.push({ name: "home" });
      });
    },
  },
});
