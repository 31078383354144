
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "DashboardView",
  components: {},
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      authenticated: "auth/isAuthenticated",
      admin: "auth/isAdmin",
    }),
  },
});
