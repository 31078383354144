/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

export default {
  namespaced: true,

  state: {
    user: null,
  },

  getters: {
    isAuthenticated(state: any) {
      return state.user != null;
    },
    isAdmin(state: any) {
      return state.user != null && state.user["role"] == "admin";
    },
    isAdminOrEmp(state: any) {
      return (
        state.user != null && ["admin", "emp"].includes(state.user["role"])
      );
    },
    isEmp(state: any) {
      return state.user != null && state.user["role"] == "emp";
    },
    isVerified(state: any) {
      return state.user.email_verified_at != null;
    },
    getUser(state: any) {
      return state.user;
    },
  },

  mutations: {
    setUser(state: any, user: object) {
      state.user = user;
    },
  },

  actions: {
    async register({ dispatch }: any, user_data: JSON) {
      return axios
        .get("sanctum/csrf-cookie")
        .then(() => {
          return axios.post("register", user_data);
        })
        .then(() => {
          return dispatch("attempt");
        });
    },
    async login({ dispatch }: any, credentials: JSON) {
      return axios
        .get("sanctum/csrf-cookie")
        .then(() => {
          return axios.post("login", credentials);
        })
        .then(() => {
          return dispatch("attempt");
        });
    },
    async attempt({ commit }: any, { do_nothing: do_nothing }: any = {}) {
      if (do_nothing) {
        return;
      }
      try {
        const response = axios.get("api/user");
        commit("setUser", (await response).data);
      } catch (error) {
        commit("setUser", null);
      }
    },
    async logout({ commit }: any) {
      return axios.post("logout").then(() => {
        commit("setUser", null);
      });
    },
  },
};
