
import { defineComponent } from "vue";
import { processMath } from "../utils";

export default defineComponent({
  props: ["modelValue"],
  emits: ["update:modelValue"],
  data() {
    return {
      textArea: "",
      isFirstChange: true,
    };
  },
  watch: {
    textArea(changedValue: string): void {
      const oldComponentJsonObj = JSON.parse(this.value);
      const newComponentJsonObj = JSON.parse("{}");
      const lines = changedValue.split("\n");
      lines.forEach((line) => {
        newComponentJsonObj[line] = oldComponentJsonObj[line]
          ? oldComponentJsonObj[line]
          : false;
      });
      const newValue = JSON.stringify(newComponentJsonObj);
      if (this.value !== newValue) {
        this.value = newValue;
      }
    },
    value(changedValue: string): void {
      if (this.isFirstChange) {
        this.textArea = Object.keys(JSON.parse(changedValue)).join("\n");
        this.isFirstChange = false;
      }
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    processMath(text: string): string {
      return processMath(text);
    },
    handleIsCorrectCheckbox(event: Event): void {
      const checkbox = event.target as HTMLInputElement;
      const option = checkbox.value;
      const componentJsonObj = JSON.parse(this.value);
      componentJsonObj[option] = checkbox.checked;
      this.value = JSON.stringify(componentJsonObj);
    },
  },
});
