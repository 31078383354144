import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
  ? process.env.VUE_APP_BASE_URL
  : "http://localhost:8000";
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

store.subscribe(async (mutation) => {
  switch (mutation.type) {
    case "auth/setUser":
      if (mutation.payload == null) {
        localStorage.setItem("logged_out", "true");
      } else {
        localStorage.removeItem("logged_out");
      }
  }
});

store
  .dispatch("auth/attempt", {
    do_nothing: localStorage.getItem("logged_out"),
  })
  .then(() => createApp(App).use(store).use(router).mount("#app"));
