
import { defineComponent } from "vue";
import { appendAll, backendPost } from "@/utils";
import { AxiosResponse } from "axios";
import { mapGetters } from "vuex";

interface FormDataVariables {
  [key: string]: {
    value: string;
    errors: [];
  };
}

export default defineComponent({
  name: "UserSettingsModal",
  data() {
    const form: FormDataVariables = {
      currentPassword: {
        value: "",
        errors: [],
      },
      newPassword: {
        value: "",
        errors: [],
      },
      newPassword_confirmation: {
        value: "",
        errors: [],
      },
    };
    return {
      form,
      passwordChanged: false,
    };
  },
  computed: {
    ...mapGetters({
      isEmp: "auth/isEmp",
    }),
  },
  methods: {
    resetForm(): void {
      this.form.currentPassword.value = "";
      this.form.currentPassword.errors = [];

      this.form.newPassword.value = "";
      this.form.newPassword.errors = [];

      this.form.newPassword_confirmation.value = "";
      this.form.newPassword_confirmation.errors = [];
    },
    changePassword(): void {
      const formData = new FormData();
      appendAll(formData, {
        currentPassword: this.form.currentPassword.value,
        newPassword: this.form.newPassword.value,
        newPassword_confirmation: this.form.newPassword_confirmation.value,
      });
      backendPost(
        "/api/userSettings/changePassword",
        formData,
        200,
        (response: AxiosResponse) => {
          this.passwordChanged = true;
          this.showErrors(response.data.errors);
          return;
        },
        (response: AxiosResponse) => {
          this.showErrors(response.data.errors);
        }
      );
    },
    showErrors(errors: Record<string, []>): void {
      for (const key in this.form) {
        this.form[key].errors = [];
      }
      for (const key in errors) {
        if (this.form[key]) {
          this.form[key].errors = errors[key];
        }
      }
    },
  },
});
