
import { defineComponent } from "vue";
import Sortable, { SortableEvent } from "sortablejs";
import {
  arrayMove,
  processMath,
  appendAll,
  backendPost,
  initDownload,
  getCurrentDate,
} from "@/utils";

export default defineComponent({
  name: "ExportPdfModal",
  props: ["selectedExercises", "allExercises"],
  data() {
    return {
      title: "",
      date: getCurrentDate(),
      withAnswers: false,
      withSolutions: false,
      orderedExercises: [] as { id: string }[],
      progressBarPercentage: 0,
      showProgress: false,
      stripedProgress: false,
      animatedProgress: false,
      progressBarDanger: false,
      statusError: "",
    };
  },
  methods: {
    processMath(text: { statement: string }): string {
      return processMath(text.statement.replaceAll("$", "#"));
    },
    resetForm(): void {
      this.title = "";
      this.date = getCurrentDate();
      this.withAnswers = false;
      this.withSolutions = false;
      this.stripedProgress = false;
      this.animatedProgress = false;
      this.progressBarDanger = false;
      this.showProgress = false;
      this.progressBarPercentage = 0;
      this.statusError = "";
    },
    async exportPdf(): Promise<void> {
      this.showProgress = true;
      this.animatedProgress = true;
      this.stripedProgress = true;
      this.progressBarPercentage = 100;
      const ids = [] as string[];
      this.orderedExercises.map((ex) => ids.push(ex.id));

      const params = new FormData();
      appendAll(params, {
        ids: JSON.stringify(ids),
        title: this.title != "" ? this.title : "Test",
        date: this.date != "" ? this.date : getCurrentDate(),
        withAnswers: this.withAnswers ? "1" : "0",
        withSolutions: this.withSolutions ? "1" : "0",
      });

      await backendPost(
        "/api/exercises/exportPdf",
        params,
        200,
        (response) => {
          initDownload(response.data["pdf"], "application/pdf", "file.pdf");
          this.resetForm();
          const closeModalButton = this.$refs.closeModal as HTMLInputElement;
          closeModalButton.click();
          this.$emit("finished-export-pdf");
        },
        (response) => {
          this.progressBarDanger = true;
          this.animatedProgress = false;
          this.statusError = response.statusText;
          return;
        },
        (progressEvent) => {
          if (progressEvent.total) {
            this.animatedProgress = false;
            this.stripedProgress = false;
            const percentage =
              (progressEvent.loaded * 100) / progressEvent.total;
            this.progressBarPercentage = percentage;
          }
        }
      );
    },
  },
  updated() {
    const selectedExList: { id: string; statement: string }[] =
      this.selectedExercises;
    const allExList: { id: string; statement: string }[] = this.allExercises;
    const selectedExercisesListElem = document.getElementById(
      "selectedExercisesList"
    );
    this.orderedExercises = [];
    for (let i = 0; i < selectedExList.length; i++) {
      const index = allExList.findIndex(
        (elem: { id: string; statement: string }) =>
          elem.id == selectedExList[i].id
      );
      this.orderedExercises.push(allExList[index]);
    }
    if (selectedExercisesListElem) {
      Sortable.create(selectedExercisesListElem, {
        animation: 150,
        onUpdate: (evt: SortableEvent) => {
          if (evt.oldIndex != undefined && evt.newIndex != undefined) {
            arrayMove(this.orderedExercises, evt.oldIndex, evt.newIndex);
          }
        },
      });
    }
  },
});
