import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
// import RegisterView from "../views/RegisterView.vue";
import DashboardView from "../views/DashboardView.vue";
import ExercisesView from "../views/ExercisesView.vue";
import NewExerciseView from "../views/NewExerciseView.vue";
import ExerciseEditView from "../views/ExerciseEditView.vue";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      onlyIfNotLoggedIn: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      onlyIfNotLoggedIn: true,
    },
  },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: RegisterView,
  //   meta: {
  //     onlyIfNotLoggedIn: true,
  //   },
  // },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: {
      onlyIfLoggedIn: true,
    },
  },
  {
    path: "/exercises",
    name: "exercises",
    component: ExercisesView,
    meta: {
      onlyIfAdminOrEmp: true,
    },
  },
  {
    path: "/exercises/new",
    name: "new-exercise",
    component: NewExerciseView,
    meta: {
      onlyIfAdminOrEmp: true,
    },
  },
  {
    path: "/edit-exercise/:id",
    name: "edit-exercise",
    component: ExerciseEditView,
    meta: {
      onlyIfAdminOrEmp: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  if (to.meta["onlyIfLoggedIn"] === true) {
    if (store.getters["auth/isAuthenticated"] === true) {
      next();
    } else {
      next({
        name: "login",
      });
    }
  } else if (to.meta["onlyIfAdmin"] === true) {
    if (store.getters["auth/isAdmin"] === true) {
      next();
    } else {
      next({
        name: "home",
      });
    }
  } else if (to.meta["onlyIfAdminOrEmp"] === true) {
    if (store.getters["auth/isAdminOrEmp"] === true) {
      next();
    } else {
      next({
        name: "home",
      });
    }
  } else if (to.meta["onlyIfNotLoggedIn"] === true) {
    if (store.getters["auth/isAuthenticated"] === true) {
      next({
        name: "dashboard",
      });
    } else {
      next();
    }
  }
});

export default router;
