import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "value", "checked"]
const _hoisted_2 = ["innerHTML", "for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("textarea", {
      class: "form-control mb-2",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textArea) = $event))
    }, null, 512), [
      [_vModelText, _ctx.textArea]
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textArea.split('\n'), (line, i) => {
      return (_openBlock(), _createElementBlock("div", { key: i }, [
        _createElementVNode("input", {
          class: "form-check-input",
          type: "checkbox",
          id: 'choice' + i.toString(),
          value: line,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleIsCorrectCheckbox && _ctx.handleIsCorrectCheckbox(...args))),
          checked: JSON.parse(_ctx.modelValue)[line]
        }, null, 8, _hoisted_1),
        _createElementVNode("label", {
          class: "ms-2 form-check-label",
          innerHTML: _ctx.processMath(line),
          for: 'choice' + i.toString()
        }, null, 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}