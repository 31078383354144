
import { ExerciseFilters } from "@/utils";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExerciseFiltersModal",
  props: ["exerciseFilters"],
  data() {
    return {
      exerciseFiltersData: {} as ExerciseFilters,
      difficultyLevels: ["Usor", "Mediu", "Avansat"],
    };
  },
  methods: {
    clearFilters() {
      this.exerciseFiltersData = {} as ExerciseFilters;
      this.$emit("update-filters", this.exerciseFiltersData);
    },
  },
  mounted() {
    this.exerciseFiltersData = this.exerciseFilters;
  },
});
