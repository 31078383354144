
import { defineComponent } from "vue";
import {
  processMath,
  appendAll,
  backendPost,
  backendGet,
  initDownload,
  showTextInNewTab,
} from "../utils";
import MultipleChoiceInput from "./MultipleChoiceInput.vue";
import { AxiosResponse } from "axios";
import { mapGetters } from "vuex";

interface FormDataVariables {
  [key: string]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    errors: [];
  };
}

export default defineComponent({
  name: "ExerciseForm",
  props: ["id"],
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  components: {
    MultipleChoiceInput,
  },
  data() {
    const form: FormDataVariables = {
      statement: {
        value: "",
        errors: [],
      },
      originalStatement: {
        value: "",
        errors: [],
      },
      multipleChoiceOptions: {
        value: "{}",
        errors: [],
      },
      selectedLevel: {
        value: 1,
        errors: [],
      },
      drawing: {
        value: new Blob(),
        errors: [],
      },
      solution: {
        value: new Blob(),
        errors: [],
      },
      minutesToSolve: {
        value: 1,
        errors: [],
      },
      publishing: {
        value: "",
        errors: [],
      },
      yearPublished: {
        value: "",
        errors: [],
      },
      sourcePageNumber: {
        value: "",
        errors: [],
      },
      subchapterName: {
        value: "",
        errors: [],
      },
      chapterName: {
        value: "",
        errors: [],
      },
      academicProgramName: {
        value: "",
        errors: [],
      },
      gradeName: {
        value: "",
        errors: [],
      },
      examType: {
        value: "non",
        errors: [],
      },
      subjectNumber: {
        value: "",
        errors: [],
      },
      exerciseNumber: {
        value: "",
        errors: [],
      },
    };
    return {
      form,
      difficultyLevels: ["Usor", "Mediu", "Avansat"],
      academicPrograms: [],
      grades: [],
      chapters: [],
      subchapters: [],
      drawingPreview: "",
      solutionPreview: "",
      denyEdit: true,
    };
  },
  mounted() {
    backendGet("/api/academic-programs/index", (response) => {
      this.academicPrograms = response.data.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (elem: { [x: string]: any }) => {
          return elem["name"];
        }
      );
    });
    backendGet("/api/grades/index", (response) => {
      this.grades = response.data.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (elem: { [x: string]: any }) => {
          return elem["name"];
        }
      );
    });
    if (this.id) {
      backendGet(
        "/api/exercises/findWithBinaries?id=" + this.id,
        (response) => {
          const academic_program = response.data["academic_program"];
          const subchapter = response.data["subchapter"];
          const chapter = subchapter["chapter"];
          const grade = chapter["grade"];

          this.form.statement.value = response.data["statement"];
          this.form.originalStatement.value =
            response.data["original_statement"];
          this.form.multipleChoiceOptions.value =
            response.data["multiple_choice_options"];
          this.form.selectedLevel.value = response.data["difficulty_level"];
          this.form.minutesToSolve.value =
            response.data["seconds_to_solve"] / 60;
          this.form.publishing.value = response.data["publishing"];
          this.form.yearPublished.value = response.data["year_published"];
          this.form.sourcePageNumber.value =
            response.data["source_page_number"];
          this.form.examType.value = response.data["exam_type"];
          this.form.subjectNumber.value = response.data["subject_number"];
          this.form.exerciseNumber.value = response.data["exercise_number"];
          this.form.academicProgramName.value = academic_program["name"];
          this.form.subchapterName.value = subchapter["name"];
          this.form.chapterName.value = chapter["name"];
          this.form.gradeName.value = grade["name"];
          if (response.data["drawingBinary"]) {
            this.drawingPreview =
              "data:image/jpeg;base64," + response.data["drawingBinary"];
          }
          if (response.data["solutionBinary"]) {
            this.solutionPreview = response.data["solution"]
              .toString()
              .endsWith(".pdf")
              ? "data:application/pdf;base64," + response.data["solutionBinary"]
              : "data:image/jpeg;base64," + response.data["solutionBinary"];
          }
          const canEdit =
            this.user["role"] === "admin" ||
            (this.user["role"] === "emp" &&
              this.user["id"] === response.data["created_by"]);
          this.denyEdit = !canEdit;
        }
      );
    } else {
      this.denyEdit = false;
      this.form.publishing.value = localStorage.getItem("publishing");
      this.form.yearPublished.value = localStorage.getItem("year_published");
    }
  },
  $refs: {
    drawingInput: HTMLInputElement,
    solutionInput: HTMLInputElement,
  },
  methods: {
    chaptersRefresh() {
      if (this.form.gradeName.value != "") {
        backendGet(
          "/api/chapters/index?gradeName=" + this.form.gradeName.value,
          (response) => {
            this.chapters = response.data.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (elem: { [x: string]: any }) => {
                return elem["name"];
              }
            );
          }
        );
      }
      this.subchaptersRefresh();
    },
    subchaptersRefresh() {
      if (
        this.form.gradeName.value != "" &&
        this.form.chapterName.value != ""
      ) {
        backendGet(
          "/api/subchapters/index?gradeName=" +
            this.form.gradeName.value +
            "&chapterName=" +
            this.form.chapterName.value,
          (response) => {
            this.subchapters = response.data.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (elem: { [x: string]: any }) => {
                return elem["name"];
              }
            );
          }
        );
      }
    },
    processMath(text: string): string {
      return processMath(text);
    },
    saveExercise() {
      const formData = new FormData();
      appendAll(formData, {
        statement: this.form.statement.value,
        originalStatement: this.form.originalStatement.value,
        multipleChoiceOptions: this.form.multipleChoiceOptions.value,
        difficultyLevel: this.form.selectedLevel.value.toString(),
        secondsToSolve: (this.form.minutesToSolve.value * 60).toString(),
        publishing: this.form.publishing.value,
        yearPublished: this.form.yearPublished.value,
        sourcePageNumber: this.form.sourcePageNumber.value,
        subchapterName: this.form.subchapterName.value,
        chapterName: this.form.chapterName.value,
        academicProgramName: this.form.academicProgramName.value,
        gradeName: this.form.gradeName.value,
        examType: this.form.examType.value,
        subjectNumber: this.form.subjectNumber.value,
        exerciseNumber: this.form.exerciseNumber.value,
      });

      formData.append(
        "drawing",
        this.form.drawing.value.size ? this.form.drawing.value : ""
      );
      formData.append(
        "solution",
        this.form.solution.value.size ? this.form.solution.value : ""
      );

      if (this.id) {
        formData.append("id", this.id);
        formData.append(
          "removeDrawing",
          this.drawingPreview === "" ? "1" : "0"
        );
        formData.append(
          "removeSolution",
          this.solutionPreview === "" ? "1" : "0"
        );
        backendPost(
          "/api/exercises/update",
          formData,
          200,
          () => {
            this.saveLocalStorage();
            this.$router.push("/exercises");
          },
          (response: AxiosResponse) => {
            const errors = response.data.errors;
            this.showErrors(errors);
          }
        );
      } else {
        backendPost(
          "/api/exercises/add",
          formData,
          201,
          () => {
            this.saveLocalStorage();
            this.$router.push("/exercises");
          },
          (response: AxiosResponse) => {
            const errors = response.data.errors;
            this.showErrors(errors);
          }
        );
      }
    },
    saveLocalStorage() {
      localStorage.setItem("publishing", this.form.publishing.value);
      localStorage.setItem("year_published", this.form.yearPublished.value);
    },
    showErrors(errors: Record<string, []>) {
      for (const key in this.form) {
        this.form[key].errors = [];
      }
      for (const key in errors) {
        if (this.form[key]) {
          this.form[key].errors = errors[key];
        }
      }
    },
    previewPdf() {
      const formData = new FormData();
      appendAll(formData, {
        statement: this.form.statement.value,
        jsonChoices: this.form.multipleChoiceOptions.value,
      });
      formData.append(
        "drawing",
        this.form.drawing.value.size ? this.form.drawing.value : ""
      );
      backendPost(
        "/api/exercises/previewExportPdf",
        formData,
        200,
        (response) => {
          initDownload(response.data["pdf"], "application/pdf", "file.pdf");
        },
        (response) => {
          showTextInNewTab(response.data["syntaxError"]);
        }
      );
    },
    handleDrawingChange(event: Event) {
      const fileInput = event.target as HTMLInputElement;
      const selectedFile = fileInput.files ? fileInput.files[0] : null;
      if (selectedFile) {
        this.form.drawing.value = selectedFile;
        this.drawingPreview = URL.createObjectURL(selectedFile);
      }
    },
    clearDrawingInput() {
      this.form.drawing.value = new Blob();
      this.drawingPreview = "";
      if (this.$refs.drawingInput) {
        const drawingInput = this.$refs.drawingInput as HTMLInputElement;
        drawingInput.value = "";
      }
    },
    handleSolutionChange(event: Event) {
      const fileInput = event.target as HTMLInputElement;
      const selectedFile = fileInput.files ? fileInput.files[0] : null;
      if (selectedFile) {
        this.form.solution.value = selectedFile;
        this.solutionPreview = URL.createObjectURL(selectedFile);
      }
    },
    clearSolutionInput() {
      this.form.solution.value = new Blob();
      this.solutionPreview = "";
      if (this.$refs.solutionInput) {
        const solutionInput = this.$refs.solutionInput as HTMLInputElement;
        solutionInput.value = "";
      }
    },
    clearEntireForm() {
      this.clearSolutionInput();
      this.clearDrawingInput();
      this.form.statement.value = "";
      this.form.originalStatement.value = "";
      this.form.multipleChoiceOptions.value = "{}";
      this.form.academicProgramName.value = "";
      this.form.gradeName.value = "";
      this.form.subchapterName.value = "";
      this.form.chapterName.value = "";
      this.form.selectedLevel.value = 1;
      this.form.minutesToSolve.value = 1;
      this.form.publishing.value = "";
      this.form.yearPublished.value = "";
      this.form.sourcePageNumber.value = "";
      this.form.examType.value = "non";
      this.form.subjectNumber.value = "";
      this.form.exerciseNumber.value = "";
    },
    btnReplaceDollarWithHashtag() {
      this.form.statement.value = this.form.statement.value.replaceAll(
        "$",
        "#"
      );
    },
    btnReplaceHashtagWithDollar() {
      this.form.statement.value = this.form.statement.value.replaceAll(
        "#",
        "$"
      );
    },
    btnRemoveAllHashtagAndDollar() {
      this.form.statement.value = this.form.statement.value.replaceAll(
        "#",
        " "
      );
      this.form.statement.value = this.form.statement.value.replaceAll(
        "$",
        " "
      );
    },
    cancelButton() {
      this.$router.push({ name: "exercises" });
    },
  },
});
